@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-gray-100 text-gray-700 dark:bg-gray-800 dark:text-gray-50;
  }

  a {
    @apply text-blue-500 dark:text-blue-400;
  }
}

@layer components {
  .card {
    @apply p-4 bg-white border border-gray-200 dark:bg-gray-700 dark:border-gray-600 shadow rounded-lg p-4 mb-2;
  }

  .pill {
    @apply px-2 py-1 inline-flex text-xs leading-5 font-semibold rounded-full;
  }

  .histogram {
    @apply h-6 w-full mx-auto;
  }
  .hitbox {
    align-items: flex-end;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    padding: 1px;
    border-radius: 3.75px;
  }
  .bar {
    @apply bg-gray-300 dark:bg-gray-600;
    padding-bottom: 1px;
    height: 100%;
    width: 85%;
    border-radius: 100px;
  }

  .bar.green {
    @apply bg-green-400 dark:bg-green-700;
  }

  .bar.red {
    @apply bg-red-400 dark:bg-red-700;
  }

  .bar.yellow {
    @apply bg-yellow-400 dark:bg-yellow-700;
  }

  .tooltip {
    @apply relative;
  }

  .tooltip .content {
    @apply invisible absolute z-50 inline-block;
    @apply rounded-lg py-1 px-2 bg-gray-100 dark:bg-gray-800 shadow;
    @apply opacity-0 transition-all duration-200 scale-50;
  }

  .tooltip:hover .content {
    @apply visible opacity-100 scale-100;
  }

  .stat-alert{
    @apply p-4 border shadow rounded-lg p-4 mb-2;
  }

  .stat-alert.red{
    @apply bg-red-200 text-red-700 dark:bg-red-700 dark:text-red-200 border-red-300 dark:border-red-600;
  }
  .stat-alert.orange{
    @apply bg-orange-200 text-orange-700 dark:bg-orange-700 dark:text-orange-200 border-orange-300 dark:border-orange-600;
  }
  .stat-alert.yellow{
    @apply bg-yellow-200 text-yellow-700 dark:bg-yellow-700 dark:text-yellow-200 border-yellow-300 dark:border-yellow-600;
  }
  .stat-alert.green{
    @apply bg-green-200 text-green-700 dark:bg-green-700 dark:text-green-200 border-green-300 dark:border-green-600;
  }
  .stat-alert.blue{
    @apply bg-blue-200 text-blue-700 dark:bg-blue-700 dark:text-blue-200 border-blue-300 dark:border-blue-600;
  }
  .stat-alert.sky{
    @apply bg-sky-200 text-sky-700 dark:bg-sky-700 dark:text-sky-200 border-sky-300 dark:border-sky-600;
  }
  .stat-alert.violet{
    @apply bg-violet-200 text-violet-700 dark:bg-violet-700 dark:text-violet-200 border-violet-300 dark:border-violet-600;
  }
  .stat-alert.indigo{
    @apply bg-indigo-200 text-indigo-700 dark:bg-indigo-700 dark:text-indigo-200 border-indigo-300 dark:border-indigo-600;
  }
  .stat-alert.pink{
    @apply bg-pink-200 text-pink-700 dark:bg-pink-700 dark:text-pink-200 border-pink-300 dark:border-pink-600;
  }
}
